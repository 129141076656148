import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import AuthPage from "./auth";
import LegalPage from "./legal";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import FaqPage from "./faq";
import blog from "./blog";
import blog1 from "./blogPage1";
import blog2 from "./blogPage2";
import blog3 from "./blogPage3";
import blog4 from "./blogPage4";
import blog5 from "./blogPage5";
import blog6 from "./blogPage6";
import blog7 from "./blogPage7";
import blog8 from "./blogPage8";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import logo from './../images/FredLogo.png';

function App(props) {
  
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <>
              <Navbar
                color="secondary"
                logo="../images/FredLogo.png"
                logoInverted="https://uploads.divjoy.com/logo-white.svg"
              />

              <Switch>
                <Route exact path="/" component={IndexPage} title="Filter Fred"/>

                {/* <Route exact path="/about" component={AboutPage} /> */}
                
                <Route exact path="/faq" component={FaqPage} title="Filter Fred FAQs"/>

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/pricing" component={PricingPage} title="Filter Fred Pricing"/>

                <Route exact path="/auth/:type" component={AuthPage} />

                <Route exact path="/legal/:section" component={LegalPage} />

                <Route exact path="/dashboard" component={DashboardPage}  title="Filter Fred Dashboard" size="large"/>
                
                <Route exact path="/blog" component={blog} title="Filter Fred Blog"/>
                <Route exact path="/blog/Ultimate-Guide-to-Filtering-YouTube" component={blog1} title="Ultimate Guide to Filtering YouTube"/>
                <Route exact path="/blog/Top-Rated-Parental-Control-Apps-for-YouTube" component={blog2} title="The Ultimate Guide to Filtering YouTube for Kids"/>
                <Route exact path="/blog/Comprehensive-Guide-to-YouTube-Kids" component={blog3} title="Unlocking Safe Entertainment: The Comprehensive Guide to YouTube Kids"/>
                <Route exact path="/blog/Step-by-Step-Guide-to-Limiting-Screen-Time-on-YouTube" component={blog4} title="A Step-by-Step Guide to Limiting Screen Time on YouTube"/>
                <Route exact path="/blog/how-to-set-time-limits-on-youtube" component={blog5} title="A Comprehensive Guide to Setting Time Limits on YouTube"/>
                <Route exact path="/blog/is-youtube-safe-for-kids" component={blog6} title="Understanding the Safety of YouTube for Kids"/>
                <Route exact path="/blog/educational-youtube-channels-for-kids" component={blog7} title="Unlock Learning: Top Educational YouTube Channels for Kids"/>
                <Route exact path="/blog/age-appropriate-youtube-channels-for-kids" component={blog8} title="Top 15 Age-Appropriate YouTube Channels for Kids"/>

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/purchase/:plan" component={PurchasePage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route component={NotFoundPage} />
                <Route exact path="/sitemap.xml" component={"../../public/sitemap.xml"} />

              </Switch>

              <Footer
                bgColor="secondary"
                size="medium"
                bgImage=""
                bgImageOpacity={1}                
                description="Filter Fred: Kids your kids safe online, Age-Appropriate YouTube Content"
                copyright={`© ${new Date().getFullYear()} 365Apps`}
                logo={logo}
                logoInverted="https://uploads.divjoy.com/logo-white.svg"
                sticky={true}
              />
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
