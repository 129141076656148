import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { makeStyles } from "@material-ui/core/styles";
import EditItemModal from "./EditItemModal";
import { useAuth } from "../util/auth";
import { createItem, updateItem, deleteItem, useEventsByOwner } from "../util/db";

import { useItemsByOwner, createSettings, updateSettings } from "../util/db";
import AgeSelector from "./AgeSelector";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor: theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  gridparent: {
    // paddingBottom: "20px",
  },
  buttonOn: {
    backgroundColor: "#00ff00",
    width: 140,
    color: "#fff",
  },
  buttonOff: {
    backgroundColor: "#ff0000",
    width: 140,
    color: "#fff",
  },
  Age18: {
    background: "#ff0000",
    color: "#fff",
  },
  Age1317: {
    background: "#ff9900",
    color: "#313",
  },
  Age712: {
    background: "#ffff00",
    color: "#313",
  },
  Age06: {
    background: "#00ff00",
    color: "#313",
  },
}));

function ControlPanelData(props) {
  const auth = useAuth();
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  let initialState = daysOfWeek.map((day) => {
    return {
      day: day,
      allowed: true,
      start: "09:00",
      end: "17:30",
    };
  });
  const [age, setAge] = useState("");
  const [extensionStatus, setExtensionStatus] = useState(false);
  const [days, setDays] = useState(initialState);
  const [state, setState] = React.useState({
    strictMode: true,
  });

  const classes = useStyles();

  const { data: settings, status: itemsStatus, error: itemsError } = useItemsByOwner(auth.user.uid);

  useEffect(() => {
    console.log("365APPS  Initial  settings:", settings);

    daysOfWeek.map((day) => {
      //find object in settings with same day
      //if found, return that object
      //if not found, return default object
      if (settings)
        if (settings.length > 0) {
          let obj = settings[0].days.find((item) => item.day === day);

          if (obj) {
            initialState[daysOfWeek.indexOf(day)] = {
              day: day,
              allowed: obj ? obj.allowed : true,
              start: obj ? obj.start : "15:00",
              end: obj ? obj.end : "19:30",
            };
          }
          setState({ ...state, strictMode: settings ? settings[0].strict : true });
        }
    });
    // setAge if settings is not []

    if (settings && settings.length > 0) {
      console.log("365APPS  useEffect  settings:", settings);
      if (settings[0].age) {
        setAge(settings[0].age); //makes this only show youngest content by default
      } else {
        setAge("0-6 years"); //makes this only show youngest content by default
      }
      props.statusCallback(settings ? settings[0].extensionStatus : false);
    }

    setDays(initialState);
  }, [settings]);

  const handleCheckedChange = (index) => (event) => {
    const newDays = [...days];
    // newDays[index].allowed = event.target.checked;
    newDays[index].allowed = !newDays[index].allowed;
    setDays(newDays);
    handleChangeAll("strict", null, newDays);
    console.log("365APPS  handleCheckedChange  newDays:", newDays);
  };

  const handleTimeChange = (index, type) => (event) => {
    const newDays = [...days];
    newDays[index][type] = event.target.value;
    setDays(newDays);
    handleChangeAll("strict", null, newDays);
  };

  const handleStrictModeChange = (event) => {
    let val = !state.strictMode;
    console.log("365APPS  handleStrictModeChange  val:", val);
    setState({ ...state, strictMode: val });

    handleChangeAll("strict", event, null);
  };
  const handleChangeAge = (ageValue) => {
    console.log("365APPS  handleChangeAge  event:", ageValue);
    // setState({ ...state, age: event });
    setAge(ageValue);

    handleChangeAll("age", ageValue, null, ageValue);
  };

  const handleChangeAll = (type, event, newDays, ageValue) => {
    const data = {
      strict: !state.strictMode,
      days: newDays ? newDays : days,
      user: auth.user.uid,
      userEmail: auth.user.email,
      age: ageValue ? ageValue : age,
    };
    console.log("365APPS  handleChangeAll  data:", data);

    createSettings(data);
  };

  // Get value of last used auth provider
  useEffect(() => {
    // handleChangeAll("strict", null, null)
    // createItem(data)
    // useItemsByOwner(auth.user.uid).then((data) => {
    //   console.log("365APPS  getSettings  data:", data);
    //   if (data) {
    //     setState({ ...state, strictMode: data.strict });
    //     setDays(data.days);
    //   }
    // });
  }, []);

  return (
    <Paper>
      <Grid container justifyContent="space-between">
        {/* <Box mb={2} p={2}> */}
        <Grid container justifyContent="center">
          <Box mb={2} p={2}>
            <Typography variant="h4">Control Panel</Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="space-between">
          <Box mb={2} p={2}>
            <Typography variant="h5">Age Group</Typography>
            <Typography variant="body1">Select the age group for your child</Typography>
            <Typography variant="body1">This setting is used to compare each video against.</Typography>
          </Box>
          <Grid container justifyContent="center">
            <Box width="75%">
              <AgeSelector callback={handleChangeAge} initialAge={age} />
            </Box>
          </Grid>
          <Box mb={2} p={2} color="#aaa"></Box>
        </Grid>
        <Grid container>
          <Box mb={2} p={2} display="flex" width="70%">
            <Grid container direction="column">
              <Typography variant="h5">Strict Mode</Typography>
              <Typography variant="body1">On = Hide the player on videos that are too old for your child</Typography>
              <Typography variant="body1">Off = Show the warning banner on the page</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={state.strictMode ? classes.buttonOn : classes.buttonOff}
                startIcon={state.strictMode ? <CheckCircleIcon /> : <Cancel />}
                onClick={handleStrictModeChange}
              >
                {state.strictMode ? "On" : "Off"}
              </Button>
            </Grid>
          </Box>
        </Grid>

        <Grid container justifyContent="space-between">
          <Box mb={2} p={2} display="flex" width="70%">
            <Typography variant="h5">Allowed Days </Typography>
          </Box>
        </Grid>
        <Grid container>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h6">Day</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Allowed on this day?</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6">Start Time</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6">End Time</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {daysOfWeek.map((day, index) => (
                <TableRow key={day}>
                  <TableCell align="left">
                    <Typography variant="h6">{day}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {/* <Checkbox
                      checked={days[index].allowed}
                      onChange={handleCheckedChange(index)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    /> */}
                    <Button
                      variant="contained"
                      color="primary"
                      className={days[index].allowed ? classes.buttonOn : classes.buttonOff}
                      startIcon={days[index].allowed ? <CheckCircleIcon /> : <Cancel />}
                      onClick={handleCheckedChange(index)}
                    >
                      {days[index].allowed ? "Yes" : "No"}
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      id={`start-${day}`}
                      label="Start"
                      type="time"
                      value={days[index].start}
                      onChange={handleTimeChange(index, "start")}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600, // 5 min
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      id={`end-${day}`}
                      label="End"
                      type="time"
                      value={days[index].end}
                      onChange={handleTimeChange(index, "end")}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {/* </Box> */}
      </Grid>
    </Paper>
  );
}

export default ControlPanelData;
