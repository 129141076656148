import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import boy1 from "./../images/boy1.png";
import laptop from "./../images/laptop.png";
import kid1 from "./../images/kid1.png";
import watch from "./../images/watch.png";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 160,
  },
}));

function ContentCardsSection(props) {
  const classes = useStyles();

  const items = [
    {
      image: kid1,
      title: "Content Fit for Kids",
      body: "Filter Fred, ensures only age-appropriate YouTube content reaches your child's screen.", // url: "/post/golden-gate",
    },
    {
      image: boy1,
      title: "Balance Screen Time",
      body: "Use Filter Fred's built-in schedules to easily manage your child's YouTube time and encourage a balanced lifestyle.",
    },
    {
      image: laptop,
      title: "Simple Parental Control",
      body: "Filter Fred makes controlling your child's YouTube experience a breeze with our easy to use dashboard ",
    },
    {
      image: watch,
      title: "Watch History at a Glance",
      body: "(Not that kind of watch) Stay updated on your child's YouTube activity with Filter Fred's easy-to-use watch history feature.",
    },
  ];

  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container>
        <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={6} md={3} lg={3} key={index}>
              <Card
                style={{
                  textDecoration: "none",
                  height: "380px",
                }}
              >
                {/* <CardActionArea component={Link} to={item.url}
                 style={{
                  textDecoration: "none",
                }}
                > */}
                  <CardMedia image={item.image} title={item.name} className={classes.media} />
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom={true}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      // component="p"
                    >
                      {item.body}
                    </Typography>
                  </CardContent>
                {/* </CardActionArea> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default ContentCardsSection;
