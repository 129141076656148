import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // // Reverse every other row
    // "&:nth-of-type(even)": {
    //   flexDirection: "row-reverse",
    // },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "1. Sign up",
      description: "Click sign up to create your account and get started",
      content: "1. Sign Up",
      path: "/auth/:type",
      type: "button",
    },
    {
      title: "2. Add to your kids Chrome browser",
      description:
        "Head over to the Chrome Webstore and add Filter Fred to your kids browser. Then sign in with the same account you sign in here.",
      content: "2. Add To Chrome",
      path: "https://chrome.google.com/webstore/detail/filterfred/kdclbeghjpdoajdpkifhhgehoegidpfg",
      type: "link",
    },
    {
      title: "3. Set your controls",
      description:
        "Use your dashboard to pick the appropriate age group for your child, and set specific times for your kid to watch YouTube",
      content: "3. Go To Dashboard",
      path: "/Dashboard",
      type: "button",
    },
    {
      title: "4. Log into FilterFred on YouTube ",
      description:
        "Activate Filter Fred by clicking the extension icon and Sign in with the same account you sign in here.",
      content: "4. Go To YouTube",
      path: "https://www.youtube.com/",
      type: "link",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              {item.content && (
                <Grid item={true} xs={12} md={6}>
                 
                  {item.type == "button" ? (
                    <Button
                      component={Link}
                      to={item.path}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      {item.content}
                    </Button>
                  ) : (
                    <Button
                      component={Link}
                      onClick={() => window.open(item.path)}
                      // to={item.path}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      {item.content}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
