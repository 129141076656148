import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Section from "../components/Section";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import blog1 from "./../pageContent/blog1.md";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "./../util/router";
import Button from "@material-ui/core/Button";
//images
import woman1 from "./../images/woman1.png";
import woman2 from "./../images/woman2.png";
import woman3 from "./../images/woman3.png";
import man3 from "./../images/man3.png";
import boy from "./../images/boy2.png";

const useStyles = makeStyles((theme) => ({

  title: {
    fontSize: "36px",
    paddingTop: "6px",
    paddingBottom: "6px",
  },

  subTitle: {
    fontSize: "28px",
    paddingTop: "6px",
    paddingBottom: "6px",
    color: theme.palette.primary.main,
  },

  subheader: {
    fontSize: "24px",
    paddingTop: "6px",
    paddingBottom: "6px",
    color: theme.palette.primary.main,
  },

  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },

  body1: {
    fontSize: "22px",
    color: "#313131",
  },

  bold: {
    fontSize: "22px",
    color: "#313131",
    fontWeight: "bold",
  },
  
}));

function BlogPage1(props) {
  const [md, setMD] = useState("");
  const classes = useStyles();

  useEffect(() => {
    fetch(blog1)
      .then((response) => response.text())
      .then((data) => {
        setMD(data);
      });
  }, []);

  return (
    <>
      <Meta
        title="The Ultimate Guide to Filtering YouTube for Kids"
        description="Practical steps for keeping your kids safe online"
      />

      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Box textAlign="left">
            <Grid
              container={true}
              justifyContent="flex-start"
              className={classes.paperItems}
            >
              <Grid item={true} >
                <Typography variant="h1" className={classes.title}>
                  The Ultimate Guide to Filtering YouTube for Kids
                </Typography>
                <Typography variant="h2" className={classes.subTitle}>
                  Practical steps for keeping your kids safe online
                </Typography>
              </Grid>
              <Grid item={true} >
                <figure>
                  <img
                    src={woman1}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>

                <Typography variant="h2" className={classes.subTitle}>
                  1. Understanding Parental Controls & Settings
                </Typography>

                <Typography variant="body1" className={classes.body1}>
                  In the digital age, parents need to keep a close eye on their
                  children's online activities. YouTube, with its vast array of
                  content, can be a tricky platform to navigate for young users.
                  Hence, it is crucial to understand the importance of parental
                  controls and settings. A well-filtered YouTube experience can
                  help ensure your child's online safety while also allowing
                  them to learn and have fun.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  1.1 YouTube Kids Profiles
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Creating a YouTube Kids profile is a simple yet effective way
                  to give your children access to YouTube. It allows them to
                  explore a wide range of fun and educational content that has
                  been specifically curated for kids. This makes it an excellent
                  tool for parents who want to provide their children with a
                  safe and age-appropriate YouTube experience. You can set up a
                  YouTube Kids profile with ease, and the settings can be
                  customized according to your child's age and content
                  preferences.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  1.2 Supervised YouTube Accounts
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  A supervised YouTube account is another effective tool for
                  parents. It is essentially a Google account you create for
                  your child with supervised access to YouTube. With such an
                  account, you have the ability to monitor your child's YouTube
                  activity and control what they can watch. You can choose to
                  block certain content and even review their watch history to
                  ensure they are only viewing appropriate materials.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  1.3 Choosing the Right YouTube Experience for Your Family
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  There are several factors to consider when choosing the right
                  YouTube experience for your family. You need to balance the
                  desire to protect your child from inappropriate content with
                  the need to give them the freedom to explore and learn. Both
                  YouTube Kids profiles and supervised YouTube accounts offer
                  robust parental controls and settings that can be customized
                  to suit your family's needs. But if you're looking for a more
                  comprehensive solution, consider using third-party parental
                  control tools like Filter Fred, which harnesses the power of
                  AI to provide advanced content filtering and a user-friendly
                  dashboard to manage your child's YouTube viewing habits. The
                  key is to find a balance that works for your family, keeping
                  your child safe online while also allowing them to have a
                  rewarding and enjoyable YouTube experience.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  2. Setting Up Parental Controls
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Navigating the digital world can be a daunting task for
                  parents, especially when it comes to ensuring your child's
                  online safety. It's a digital jungle out there and you'd want
                  to shield your little ones from inappropriate content on
                  platforms like YouTube. But fret not, in this guide we are
                  going to walk you through how to set up parental controls on
                  YouTube, making it a safer place for your child.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  2.1 Using YouTube Settings
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Setting up YouTube parental controls is as easy as pie. All
                  you need to do is sign in to your linked parent account on
                  YouTube. Once you're in, head over to your profile picture and
                  click on 'Settings'. From there, select 'Parent Settings'. If
                  you're using a computer, there's an additional step to manage
                  settings for your kids right next to 'Parent Settings'. This
                  simple process gives you access to a whole new world of
                  parental controls which can be altered to suit your child's
                  needs.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  2.2 Using Family Link App
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Google's Family Link app is another excellent tool for parents
                  to monitor and control their child's YouTube activity. To
                  begin, open the Family Link app on your device. Select your
                  child's profile and tap on 'Controls' followed by 'Content
                  restrictions' and then 'YouTube'. This will reveal a plethora
                  of parental controls and settings for YouTube Kids profiles or
                  supervised YouTube experiences. The app allows you to change
                  settings that apply to both YouTube and YouTube Kids under
                  'General Settings'.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  2.3 Setting Up a YouTube Kids Profile
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  YouTube Kids is a platform dedicated to providing
                  age-appropriate content for children. When setting up a
                  YouTube Kids profile for your child, you can tailor the
                  viewing experience by adjusting specific parental controls. To
                  do this, select the Lock icon in the corner of your screen,
                  solve the multiplication problem or enter your custom
                  passcode, and finally, select 'Settings'. From here you can
                  block content, change your child’s content level, turn off the
                  Search feature, and even review your child’s watch history. By
                  taking these steps, you can create a safe and enjoyable
                  YouTube environment for your child.
                </Typography>
              </Grid>
              <Grid item={true} >
                <figure>
                  <img
                    src={woman2}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>
                <Typography variant="h3" className={classes.subTitle}>
                  3. Viewing and Changing Parental Controls & Settings
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  In a digital age where content is king, ensuring your child's
                  online safety can feel like a game of chess. But fear not, as
                  there are a multitude of ways to set up and adjust parental
                  controls for a more child-friendly YouTube experience. These
                  controls allow you to block or share content, change content
                  levels, disable the search feature and review your child’s
                  watch history. Let's dive into how you can safeguard your
                  children's YouTube viewing through YouTube Kids, linked parent
                  account's YouTube settings, and the Family Link app.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  3.1 Using YouTube Kids
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  YouTube Kids offers a host of parental controls that you can
                  customize to cater to your child's age and content
                  preferences. To access these settings, select the Lock icon
                  located in the corner of your screen, solve the multiplication
                  problem or enter your custom passcode, and finally, select
                  Settings. From here, you can adjust what content is accessible
                  to your child, limit their screen time, and even review their
                  watch history. For an even more curated experience, you can
                  change your child’s YouTube Kids content setting to 'Approved
                  content only.'
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  3.2 Using Linked Parent Account's YouTube Settings
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Another way to manage your child's YouTube experience is by
                  using the YouTube settings linked to your parent account.
                  Start by signing in to YouTube with your linked parent
                  account, then go to your profile picture and select Settings.
                  From there, choose Parent Settings and if you’re using a
                  computer, select 'Manage settings for your kids.' This option
                  allows you to oversee your child’s viewing habits and adjust
                  their content restrictions.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  3.3 Using the Family Link App
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Google's Family Link app is another great tool for managing
                  your child's online activity. Launch the app on your device,
                  select your child, and tap 'Controls' followed by 'Content
                  restrictions,' and then 'YouTube.' From here, you can adjust
                  your child’s YouTube Kids settings, supervised YouTube
                  experience settings, and even settings that apply to both
                  YouTube and YouTube Kids. The Family Link app empowers you to
                  create a balanced and healthy digital environment for your
                  child. By staying proactive and using these tools, you can
                  provide a safe, age-appropriate YouTube experience for your
                  child. With a bit of setup and occasional monitoring, you can
                  ensure that your child's YouTube viewing is both safe and
                  enjoyable.
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  ## 4. Tailoring YouTube Kids or Supervised YouTube Experience
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Creating a safe digital environment for your kids on YouTube
                  might seem like a daunting task but with the right tools and
                  know-how, it can turn into a breeze. Let's dive into how you
                  can customize your child's YouTube experience.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.1 Blocking or Sharing Content with Your Child
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  You can create a custom viewing experience for your child by
                  blocking specific channels or videos directly from their
                  device. Alternatively, you can also share approved content
                  with your child by signing into your Google Account.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.2 Changing Content Level Setting
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Adjusting your child's content level setting can ensure that
                  they watch age-appropriate videos. This can be done easily
                  through your child's YouTube Kids settings. You can select
                  content levels based on your child's age, or you can set it to
                  'approved content only.'
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.3 Turning Off Search Feature for YouTube Kids
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  To limit your child's exposure to a wider range of videos, you
                  can turn off the Search feature in YouTube Kids. This will
                  restrict them to videos and channels that have been verified
                  by YouTube Kids, creating a safer browsing environment.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.4 Reviewing Your Child’s Watch History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Stay informed of your child's viewing habits by reviewing
                  their watch history. This will give you insights into their
                  preferences. Do note that this has to be done from your
                  child's device.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.5 Clearing Your Child’s History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  If you want to erase your child's YouTube viewing history, you
                  can easily do so from your child's device. This can help if
                  you want to reset their recommendations.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.6 Disabling Autoplay
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  By disabling autoplay, you can prevent a series of videos from
                  playing automatically, giving you more control over what your
                  child watches next.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.7 Pausing Your Child’s Watch History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  By pausing your child's watch history, YouTube stops saving
                  what your child has viewed, which can prevent them from seeing
                  specific content again in their recommendations.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.8 Pausing Your Child’s Search History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Similarly, you can pause your child's search history to limit
                  the type of content they're exposed to in the future.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  4.9 Turning on YouTube Premium Benefits for Your Child
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  With YouTube Premium, your child can enjoy an ad-free
                  experience, offline viewing, and background play. You can turn
                  on these benefits for your child by signing into your Google
                  Account. With these steps, you can effectively tailor your
                  child's YouTube experience, ensuring they enjoy content that's
                  suitable and safe for their age range.
                </Typography>
              </Grid>
              <Grid item={true} >
                <figure>
                  <img
                    src={man3}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>
                <Typography variant="h3" className={classes.subTitle}>
                  5. Detailed Parental Controls & Settings Guide
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  As a parent, you want your child to enjoy the vast world of
                  content on YouTube, but without the potential exposure to
                  inappropriate material. Here's a detailed guide on how to
                  harness the power of YouTube's parental controls and settings
                  to ensure a safe and age-appropriate viewing experience.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.1 How to Block Content
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  YouTube enables parents to block content directly from the
                  home screen or watch page of YouTube Kids. Simply sign in with
                  your linked parent account to block specific channels that you
                  deem unsuitable.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.2 How to Share Content
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Sharing content with your child is a breeze. All you need is
                  to sign in to your Google Account, and you can share videos
                  and channels from YouTube with your linked child accounts.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.3 How to Change Content Level Settings from YouTube Kids
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  To adjust the content level settings on YouTube Kids, go to
                  Settings under your child's YouTube Kids profile. From there,
                  you can select content levels based on your child's age -
                  Preschool, Younger, Older, or Approve content yourself.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.4 How to Change Content Level Settings from YouTube
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  For a supervised experience on YouTube, sign in to YouTube
                  with your linked parent account, go to your profile picture,
                  and select Settings. Under Parent Settings, select your
                  child's profile or account, and edit the content settings as
                  necessary.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.5 How to Change Content Level Settings from Family Link App
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  You can also manage your child's content level settings via
                  the Family Link app. Simply select "YouTube Kids" or "YouTube
                  and YouTube Music" to adjust the settings.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.6 How to Use Approved Content Only in YouTube Kids
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  To restrict your child's YouTube Kids experience to approved
                  content only, switch the content setting in the YouTube Kids
                  app. Anything you share from YouTube will also be available in
                  YouTube Kids.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.7 How to Turn Off Search Feature for YouTube Kids
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  To disable the search feature in YouTube Kids, go to the
                  settings and turn off the search function. This helps limit
                  your child's exposure to potentially unsuitable content.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.8 How to Review Your Child's Watch History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Stay informed of your child's viewing habits by reviewing
                  their watch history. This can be done directly from your
                  child's device or through your linked parent account's YouTube
                  settings.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.9 How to Clear History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Clearing your child's history can be done from YouTube
                  settings. This helps ensure that any unsuitable content they
                  may have come across is removed from their record.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.10 How to Disable Autoplay
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Disabling autoplay prevents a continuous stream of videos,
                  which might include inappropriate content. This can be
                  adjusted from the settings.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.11 How to Pause Watch History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Pausing your child's watch history can be useful if you want
                  to stop tracking their viewing habits for a while. This can
                  also be done from the settings.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.12 How to Pause Search History
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  If you wish to pause your child's search history, you can do
                  so from the settings. This stops YouTube from recording your
                  child's search queries.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  5.13 How to Use YouTube Premium with Your Child’s YouTube Kids
                  Profile or Supervised Account
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Turning on YouTube Premium benefits for your child allows them
                  to enjoy an ad-free experience, offline videos, and background
                  play. You can activate these benefits from the settings in
                  your child's YouTube Kids profile or their supervised YouTube
                  account.
                </Typography>
              </Grid>
              <Grid item={true} >
                <figure>
                  <img src={boy} alt="illustration" className={classes.image} />
                </figure>
                <Typography variant="h3" className={classes.subTitle}>
                  6. Troubleshooting Common Issues
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  As a parent, you might sometimes encounter hiccups when
                  filtering YouTube for kids. However, these issues are not
                  insurmountable, and with a little understanding and patience,
                  you can quickly troubleshoot them, ensuring your child enjoys
                  a safe and age-appropriate viewing experience.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  6.1 Understanding Content Restrictions
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Content restrictions in YouTube Kids are designed to ensure
                  that your child only views age-appropriate content. YouTube
                  Kids offers different content level settings based on your
                  child's age, which you can adjust in the YouTube Kids
                  settings. You can also block specific content or channels
                  directly from your child's device or from your linked parent
                  account. There's also the option to change your child's
                  content setting to Approved content only, giving you even more
                  control over what they watch.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  6.2 Reporting Inappropriate Content
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Despite the safeguards in place, there may be instances where
                  inappropriate content slips through the cracks. If your child
                  stumbles upon such content, you can report it for a quick
                  review. This feature is easily accessible in both YouTube Kids
                  and the supervised YouTube experience. Remember, your
                  vigilance plays a crucial role in enhancing the safety and
                  efficacy of these platforms.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  6.3 Managing Multiple Children's Profiles
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  If you have more than one child, managing their individual
                  YouTube experiences can become a bit complex. You can create
                  separate profiles for each child in YouTube Kids, each with
                  their unique settings and restrictions. This feature allows
                  you to customize the content and controls based on your
                  child's age and maturity level.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  6.4 Understanding the Impact of Search History on
                  Recommendations
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Your child's search history has a significant impact on the
                  content recommended to them. When search history is paused, it
                  disconnects the link between the search terms and the
                  recommended videos, leading to a more generic set of
                  recommended videos. However, when the search feature is turned
                  on, your child can discover new videos that align with their
                  interests, but there's also a chance they may find something
                  you don't want them to watch.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  6.5 Understanding the Impact of Watch History on
                  Recommendations
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Much like search history, your child's watch history also
                  influences the recommended content. If your child frequently
                  watches a particular type of video, YouTube's algorithm will
                  suggest similar content. You can review your child's watch
                  history from their device, and if necessary, you can pause
                  their watch history, which stops YouTube from using their past
                  viewing behavior to recommend videos. By understanding these
                  common issues and how to troubleshoot them, you can provide a
                  safer and more controlled YouTube environment for your child.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  7. Concluding Remarks
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  The internet is a treasure trove of information and
                  entertainment, but it's also a labyrinth filled with content
                  that may not be suitable for children. Ensuring a safe and
                  age-appropriate YouTube experience for your little ones can be
                  a daunting task, but it's an essential one in today's digital
                  age.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  7.1 Importance of Monitoring Your Child's YouTube Use
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  Monitoring your child's YouTube consumption is not about
                  invading their privacy, but ensuring their safety and
                  wellbeing. The digital world offers a myriad of content, some
                  of which may be inappropriate or harmful to a child's
                  development. By utilizing tools like history tracking and
                  content blocking, parents can actively participate in shaping
                  their child's digital journey. This not only guarantees a safe
                  online environment but also provides opportunities for
                  learning and growth.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  7.2 Benefits of Using YouTube Kids and Supervised Accounts
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  YouTube Kids and supervised accounts are designed to provide a
                  controlled environment for your child's digital exploration.
                  These platforms are optimized to serve age-appropriate
                  content, making it a safe space for your child to learn,
                  explore, and be entertained. The parental control features
                  allow you to set viewing limits, block undesirable content,
                  and even share videos of interest, making it a collaborative
                  experience.
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  7.3 Continual Updating of Parental Controls & Settings
                </Typography>
                <Typography variant="body1" className={classes.body1}>
                  As technology evolves, so do the tools that help us navigate
                  it. Parental controls and settings are continually updated to
                  keep pace with the ever-changing digital landscape. Regularly
                  updating these settings ensures your child's online experience
                  remains safe and relevant. Tools like Filter Fred, which uses
                  AI technology for advanced content filtering, ensure that your
                  child only accesses content that's right for their age and
                  maturity. In conclusion, filtering YouTube for kids is not
                  just about restricting access, it's about guiding your child
                  through the digital world in a way that's safe,
                  age-appropriate, and conducive to their overall growth and
                  development. With the right tools and settings, you can
                  transform YouTube into a valuable resource for your child's
                  learning and entertainment.
                </Typography>
                <figure>
                  <img
                    src={woman2}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>
              </Grid>

              <Grid item={true} >
                <br />
                <br />
                <Typography className={classes.subTitle} variant="h2">
                  Found our Ultimate Guide to Filtering YouTube helpful? It's
                  time to put your newfound knowledge into practice!
                </Typography>{" "}
                <br />
                <br />
                <Typography className={classes.body1} variant="body1">
                  FilterFred makes it easier than ever to manage and filter your
                  YouTube content, ensuring a safe and balanced digital
                  environment. Click below to return to our homepage and explore
                  all the features we offer.
                </Typography>
                <br />
                <br />
                <Button
                  className={classes.cta}
                  component={Link}
                  variant="contained"
                  color="primary"
                  size="large" to="/"
                  // fullWidth={true}
                >
                  <Typography>Try Filter Fred today! </Typography>
                </Button>{" "}
                <br />
                <br />
                <Typography className={classes.subheader} variant="h3">
                  Start your journey to a more mindful and controlled digital
                  experience with FilterFred today.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>

      {/* <ReactMarkdown>This is a [test](https://google.com)                </Typography>
       */}

      {/* <ReactMarkdown># Hello, *World*!                </Typography>
       */}
    </>
  );
}

export default BlogPage1;
