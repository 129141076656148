import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { updateItem, deleteItem, useEventsByOwner } from "./../util/db";
import { useAuth } from "./../util/auth";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  table: {
    td: {
      padding: "8px",
    },
  },
  parentRow: {
    background: theme.palette.background.default,
  },
  first:{
    maxWidth:"40vw"
  },
  Age18: {
    background: "#ff0000",
    color: "#fff",
    border: "8px white solid",
  },
  Age1317: {
    background: "#ff9900",
    color: "#313",
    border: "8px white solid",
  },
  Age712: {
    background: "#dfdf51",
    color: "#313",
    border: "8px white solid",
  },
  Age06: {
    background: "#00ff00",
    color: "#313",
    border: "8px white solid",
  },
}));

export default function HistoryDataTable(props) {

  const classes = useStyles();

  const auth = useAuth();

  const { data: items, status: itemsStatus, error: itemsError } = useEventsByOwner(auth.user.uid);

  const itemsAreEmpty = !items || items.length === 0;

  
  const navigate = (url) => {
    window.open(url, "_blank");
  };

  const TimeFormat = (item) => {
    let FormattedTime;
    //if time is string and starts with 20
    if (typeof item.time === "string" && item.time.startsWith("20")) {
      //convert to date
      let x = new Date(item.time);
      FormattedTime = x.toLocaleString();
    }

    //if time is a date
    if (item.time instanceof Date) {
      //return formatted date
      FormattedTime = item.time.toLocaleString();
    }

    return <>{FormattedTime}</>;
  };

  function groupByDate(items) {
    if (items) {
      if (items.length !== 0) {
        let globalLatestTimestamp = items[0].timestamp;
        let globalLatestDate = new Date(globalLatestTimestamp).toLocaleDateString();
        let globalLatestTime = new Date(globalLatestTimestamp).toLocaleTimeString();
  
        const result = items.reduce((acc, item) => {
          const date = new Date(item.timestamp).toLocaleDateString();
          const time = new Date(item.timestamp).toLocaleTimeString();
          const timestamp = item.timestamp;
  
          if (!acc[date]) {
            acc[date] = {
              items: [],
              earliestTime: time,
              latestTime: time,
              earliestTimestamp: timestamp,
              latestTimestamp: timestamp,
            };
          }
  
          acc[date].items.push(item);
  
          if (timestamp < acc[date].earliestTimestamp) {
            acc[date].earliestTime = time;
            acc[date].earliestTimestamp = timestamp;
          }
  
          if (timestamp > acc[date].latestTimestamp) {
            acc[date].latestTime = time;
            acc[date].latestTimestamp = timestamp;
          }
  
          // Update global latest timestamp, date, and time
          if (timestamp > globalLatestTimestamp) {
            globalLatestTimestamp = timestamp;
            globalLatestDate = date;
            globalLatestTime = time;
          }  
          return acc;
        }, {});
  
        // Attach global latest date and time to the result        
        props.callback(globalLatestDate, globalLatestTime);
  
        return result;
      }
    }
  }
  

  const groupedItems = groupByDate(items);
  
  
  const [openStates, setOpenStates] = React.useState({});

  const handleClick = (date) => {
    setOpenStates((prevOpenStates) => ({
      ...prevOpenStates,
      [date]: !prevOpenStates[date],
    }));
  };
  const ToggleAll = () => {
    {
      Object.entries(groupedItems).map(([date, dateGroup]) => {
        setOpenStates((prevOpenStates) => ({
          ...prevOpenStates,
          [date]: !prevOpenStates[date],
        }));
      });
    }
  };

  return (
    <Paper>
      <Grid container direction="column" justifyContent="space-between" alignItems="center">
      <Box mb={2} p={2}>
            <Typography variant="h4">Viewing History</Typography>
          </Box>

        <Grid container>
          <TableContainer component={Paper}>
            {groupedItems && Object.keys(groupedItems).length > 0 && (
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Start</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">End</Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small" onClick={() => ToggleAll()}>
                        {Object.values(openStates).some((state) => state) ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}{" "}
                      </IconButton>
                    </TableCell>
                    {/* <TableCell>Video Title</TableCell>
              <TableCell>Classification</TableCell>
              <TableCell>Date & Time</TableCell>
            */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(groupedItems).map(([date, dateGroup]) => (
                    // {Object.keys(groupedItems).map((date) => (
                    <React.Fragment key={date}>
                      <TableRow hover onClick={() => handleClick(date)}
                        className={classes.parentRow}
                      >
                        <TableCell>{date}</TableCell>
                        <TableCell>{dateGroup.earliestTime}</TableCell>
                        <TableCell>{dateGroup.latestTime}</TableCell>
                        <TableCell>
                          <IconButton aria-label="expand row" size="small">
                            {openStates[date] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow id="Subtable">
                        <TableCell colSpan={4}>
                          {openStates[date] && (
                            <Table className={classes.table} aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Video Title</TableCell>
                                  <TableCell>Classification</TableCell>
                                  <TableCell>Date & Time</TableCell>
                                  <TableCell>View</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <>
                                  {dateGroup.items.map((row, index) => {
                                    const resultClass = row.values.text.includes("18")
                                      ? classes.Age18
                                      : row.values.text.includes("17")
                                      ? classes.Age1317
                                      : row.values.text.includes("12")
                                      ? classes.Age712
                                      : row.values.text.includes("6")
                                      ? classes.Age06
                                      : classes.Age06;

                                    return (
                                      <React.Fragment key={row.id}>
                                        <TableRow hover>
                                          <TableCell component="td" scope="row"
                                          className={classes.first}
                                          >
                                            {row.values.videoTitle}
                                          </TableCell>
                                          <TableCell component="td" scope="row" className={resultClass}>
                                            {row.values.text}
                                          </TableCell>
                                          <TableCell component="td" scope="row">
                                            <TimeFormat time={row.timestamp} />
                                          </TableCell>
                                          <TableCell component="td" scope="row">
                                            <Button
                                              variant="contained"
                                              size="medium"
                                              color="primary"
                                              onClick={() => navigate(row.values.url)}
                                            >
                                              YouTube{" "}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              </TableBody>
                            </Table>
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
        {!groupedItems && <Typography variant="h6">No History</Typography>}
      </Grid>
    </Paper>
  );
}
