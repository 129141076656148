import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import Box from "@material-ui/core/Box";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";

import { Link } from "./../util/router";
import laptop from "./../images/laptop.png";
import kid1 from "./../images/kid1.png";
import watch from "./../images/watch.png";
import Section from "../components/Section";

//images
import woman1 from "./../images/woman1.png";
import woman2 from "./../images/woman2.png";
import woman3 from "./../images/woman3.png";
import man3 from "./../images/man3.png";
import boy5 from "./../images/boy5.png";
import fam2 from "./../images/fam2.png";
import fam4 from "./../images/fam4.png";
import fam9 from "./../images/fam9.png";
import fam5 from "./../images/fam5.png";

const useStyles = makeStyles((theme) => ({
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  card: {
    margin: "20px",
    textDecoration: "none !important",
  },
  cardContent: {
    margin: "10px",
    padding: "50px",
    textDecoration: "none !important",
  },
  cardTitle: {
    textDecoration: "none !important",
    fontWeight: "bold",
    fontSize: "36px",
    padding: "10px",
    color: theme.palette.primary.main,
  },
  cardSubTitle: {
    padding: "10px",
    paddingBottom: "0px",
    fontSize: "30px",
  },
  cardDate: {
    paddingTop: "0px",
    padding: "10px",
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
  cardIntro: {
    padding: "10px",
    fontSize: "26px",
  },
  media: {
    height: 560,
    width: 460,
    minHeight: 560,
    margin: "auto",
  },
}));

function Blog(props) {
  const [blogPages, setBlogPages] = useState(null);

  const classes = useStyles();

  const getBlogPages = [
    {
      image: woman3,
      link: "/blog/Ultimate-Guide-to-Filtering-YouTube",
      title: "The Ultimate Guide to Filtering YouTube for Kids",
      subTitle: "Practical steps for keeping your kids safe online",
      intro:
        "In the digital age, parents need to keep a close eye on their children's online activities. YouTube, with its vast array of content, can be a tricky platform to navigate for young users. Hence, it...", //200 chars
      date: "June 2023",
      dateNum: "6123",
    },
    {
      image: woman2,
      link: "/blog/Top-Rated-Parental-Control-Apps-for-YouTube",
      title:
        "Unveiling the Top-Rated Parental Control Apps for YouTube Safety!",
      subTitle: "The Top 8 Parental Control Apps for YouTube",
      intro:
        "In a world where screen time is inevitable for children, it has become more important than ever to ensure that they're safe while exploring the digital realm...",
      date: "June 2023",
      dateNum: "6223",
    },
    {
      image: woman1,
      link: "/blog/Comprehensive-Guide-to-YouTube-Kids",
      title:
        "Unlocking Safe Entertainment: The Comprehensive Guide to YouTube Kids",
      subTitle: "Improving your child's YouTube experience",
      intro:
        "In an era where digital content is as ubiquitous as air, finding a safe and engaging platform for kids to enjoy and learn from can seem like finding a needle in a haystack. The challenge of navigating...",
      date: "June 2023",
      dateNum: "6323",
    },
    {
      image: fam2,
      link: "/blog/Step-by-Step-Guide-to-Limiting-Screen-Time-on-YouTube",
      title: "A Step-by-Step Guide to Limiting Screen Time on YouTube",
      subTitle: "Get help with your child's YouTube addiction",
      intro:
        "In today's digital age, parents are faced with the challenging task of managing their children's screen time. YouTube, a platform teeming with a vast array of content, is one of the most popular onlin...",
      date: "June 2023",
      dateNum: "6423",
    },
    {
      image: boy5,
      link: "/blog/how-to-set-time-limits-on-youtube",
      title: "A Comprehensive Guide to Setting Time Limits on YouTube",
      subTitle: "Ensuring a Safe and Balanced YouTube Experience for Kids",
      intro:
        "In today's digital age, YouTube has become an integral part of our lives, especially for children. Kids today spend countless hours exploring the world through YouTube videos, whether it's learning about dinosaurs, watching their favorite cartoons, or trying out a new DIY project. ...",
      date: "June 2023",
      dateNum: "6523",
    },
    {
      image: fam9,
      link: "/blog/is-youtube-safe-for-kids",
      title: "Understanding the Safety of YouTube for Kids",
      subTitle: "Fostering a safe, positive online environment",
      intro:
        "Welcome to the digital age, where YouTube reigns supreme as one of the most popular online platforms for children and teens. ...",
      date: "June 2023",
      dateNum: "6623",
    },
    {
      image: fam4,
      link: "/blog/educational-youtube-channels-for-kids",
      title: "Unlock Learning: Top Educational YouTube Channels for Kids",
      subTitle: "Dive into the amazing world of YouTube learning",
      intro:
        "For parents in the digital age, finding the balance between screen time and learning can be a significant challenge. ...",
      date: "July 2023",
      dateNum: "7123",
    },
    {
      image: fam5,
      link: "/blog/age-appropriate-youtube-channels-for-kids",
      title: "Top 15 Age-Appropriate YouTube Channels for Kids",
      subTitle: "Great content on YouTube",
      intro:
        "In the digital age, YouTube has become a go-to source of entertainment and education for children of all ages. ...",
      date: "Aug 2023",
      dateNum: "8123",
    },

    // {
    //   image: man,
    //   title: "Watch History at a Glance",
    //   body: "(Not that kind of watch) Stay updated on your child's YouTube activity with Filter Fred's easy-to-use watch history feature.",
    // },
  ];
      useEffect(() => {

          //sort blogpages by date descending
          getBlogPages.sort(function (a, b) {
            return b.dateNum - a.dateNum;
          });


      setBlogPages(getBlogPages);
    }, []);

  return (
    <>
      <Meta
        title="Filter Fred Blog"
        // description="Practical steps for      your kids safe online"
      />

      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        {/* <Container> */}
          <Box textAlign="left">
            <Grid
              container={true}
              justifyContent="center"
              className={classes.paperItems}
            >
              {blogPages?.map((item, index) => (
                <Grid
                  item={true}
                  xs={12}
                  md={6}
                  lg={4}
                  key={index}
                  className={classes.card}
                >
                  <Card component={Card} to={item.link} color="inherit">
                    <CardMedia
                      image={item.image}
                      title={item.name}
                      component={Link}
                      to={item.link}
                      className={classes.media}
                    />
                    <CardContent
                      className={classes.cardContent}
                      component={Link}
                      to={item.link}
                    >
                      <Typography
                        variant="h5"
                        component="p"
                        className={classes.cardTitle}
                      >
                        {item.title}
                      </Typography>
                      <Grid>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className={classes.cardSubTitle}
                        >
                          {item.subTitle}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          className={classes.cardDate}
                        >
                          {item.date}
                        </Typography>
                      </Grid>
                      <Typography
                        variant="body2"
                        gutterBottom={true}
                        color="textSecondary"
                        className={classes.cardIntro}
                      >
                        {item.intro}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        {/* </Container> */}
      </Section>

      {/* <ReactMarkdown>This is a [test](https://google.com)</ReactMarkdown> */}

      {/* <ReactMarkdown># Hello, *World*!</ReactMarkdown> */}
    </>
  );
}

export default Blog;
