import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What is Filter Fred?",
      answer:
        "Filter Fred is an advanced parental control app for YouTube that helps ensure your child is exposed only to age-appropriate content. It also allows you to set screen time limits, monitor your child's watch history, and manage their YouTube experience.",
    },
    {
      question: "How does Filter Fred work?",
      answer:
        "Filter Fred uses OpenAI technology to analyze YouTube video transcripts and determine if the content is suitable for your child's age group. It also provides tools for setting screen time limits, viewing watch history, and managing your child's access to YouTube.",
    },
    {
      question: "Can I monitor my child's YouTube watch history?",
      answer:
        "Yes, Filter Fred provides a  watch history feature that allows you to monitor your child's YouTube activity, including what they're watching and when they're watching it, as well as the designated age group classification provided by Filter Fred.",
    },
    {
      question: "How do I install Filter Fred?",
      answer:
        "Installing Filter Fred is simple. Head over to the Chrome Webstore and add Filter Fred to your browser with just a click.",
    },
    {
      question: "How do I set up Filter Fred?",
      answer:
        "Head to the Dashboard Control Panel. Here you can pick the appropriate age group for your child to ensure a tailored viewing experience. You can also set start and end times, for each day of the week, to manage your child's access to YouTube.",
    },    
   
    {
      question: "What platforms does Filter Fred support?",
      answer:
        "Currently, Filter Fred is available as an extension for the Google Chrome browser.",
    },
    {
      question: "Is Filter Fred safe and secure?",
      answer:
        "Yes, Filter Fred uses advanced technology to ensure the privacy and security of your child's browsing data.",
    },
    {
      question: "Does it work on all videos?",
      answer:
        "Filer Fred can only classify videos that have a transcript. If a video does not have a transcript, it will be classified as 'Not Rated'. It will still show in history though",
    },
   
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
