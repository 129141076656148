import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  root: {
    width: "80%",
    height: 64,
    margin: 64,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  Age18: {
    background: "#ff0000",
    color: "#fff",
    border: "8px white solid",
  },
  Age1317: {
    background: "#ff9900",
    color: "#313",
    border: "8px white solid",
  },
  Age712: {
    background: "#dfdf51",
    color: "#313",
    border: "8px white solid",
  },
  Age06: {
    background: "#00ff00",
    color: "#313",
    border: "8px white solid",
  },
});

const marks = [
  {
    value: 1,
    label: "0-6 years",
  },
  {
    value: 2,
    label: "7-12 years",
  },
  {
    value: 3,
    label: "13-17 years",
  },
  {
    value: 4,
    label: "18+ years",
  },
];

function valuetext(value) {
  return `${value}`;
}

function valueLabelFormat(value) {
  let m = marks.findIndex((mark) => mark.value === value);

  return marks[m].label;
}

export default function AgeSelector(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  let CustomSlider = withStyles({
    root: {
      color: value == 4 ? "#ff0000" : value == 3 ? "#ff9900" : value == 2 ? "#dfdf51" : "#00ff00",
      height: 64,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    //   valueLabel: {
    //     left: 'calc(-50% + 4px)',
    //     height: 24,
    //     width: 24,
    //     color: '#fb3569',
    //   },
    markLabel: {
      // color: "#fb3569",
      marginTop: 18,
      fontSize: 22,
    },
    mark: {
      backgroundColor: "#fff",
      height: 18,
      width: 5,
      marginTop: 0,
    },
    track: {
      height: 18,
      borderRadius: 4,
    },
    rail: {
      height: 18,
      borderRadius: 4,
    },
  })(Slider);


  function chosenvalue(value) {
    let m = marks.findIndex((mark) => mark.value === value);
    value = m;
    console.log('365APPS  chosenvalue  m:', m);
    props.callback(marks[m].label);
    return marks[m].label;
  }
  
  // Add the debounce function to chosenvalue
  const debouncedChosenValue = debounce(chosenvalue, 200);
  
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  React.useEffect(() => {
    console.log('365APPS  React.useEffect  props.initialAge:', props.initialAge);
    switch (props.initialAge) {
      case "0-6 years":
        setValue(1);
        break;
      case "7-12 years":
        setValue(2);
        break;
      case "13-17 years":
        setValue(3);
        break;
      case "18+ years":
        setValue(4);
        break;
    }
  }, [props]);

  return (
    <Grid container justifyContent="space-between">
      <CustomSlider
        defaultValue={value}
        // className={value == 2 ? classes.Age18 : classes.Age06}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-restrict"
        step={1}
        min={1}
        max={4}
        // valueLabelDisplay="on"
        marks={marks}
        onChange={(e, value) => debouncedChosenValue(value)}
        />
    </Grid>
  );
}
