import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
import ContentCardsSection from "./../components/ContentCardsSection";
import FeaturesSection from "./../components/FeaturesSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import StatsSection from "./../components/StatsSection";
import FaqSection from "./../components/FaqSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import PricingSection from "./../components/PricingSection";
import logo from './../images/FredLogo.png';
import '../styles/custom.css';



function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Provide a Kid-Friendly YouTube Experience with Filter Fred"
        subtitle="Age-Appropriate YouTube Content for kids"
        image={logo}
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/pricing"
      />
      {/* <ClientsSection
        bgColor="light"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      /> */}
      <ContentCardsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle=""
      />
       {/* <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      <FeaturesSection
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Get Started with Filter Fred in 4 Simple Steps:"
        subtitle=""
      />
      <FeaturesSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        image="https://uploads.divjoy.com/undraw-chatting_2yvo.svg"
      />
      {/* <StatsSection
        bgColor="secondary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      /> */}
      {/* <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      /> */}
     
      <PricingSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
      />
    </>
  );
}

export default IndexPage;
