import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Section from "../components/Section";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import blog from "./../pageContent/blog7.md";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "../util/router";
import Button from "@material-ui/core/Button";

//images
import fam6 from "./../images/fam6.png";
import fam7 from "./../images/fam7.png";
import fam8 from "./../images/fam8.png";
import fam9 from "./../images/fam9.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "36px",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  subTitle: {
    fontSize: "28px",
    paddingTop: "6px",
    paddingBottom: "16px",
    color: theme.palette.primary.main,
  },
  subheader: {
    fontSize: "24px",
    paddingTop: "6px",
    paddingBottom: "16px",
    color: theme.palette.primary.main,
  },
  image: {
    margin: "0 auto",
    padding: "20px",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  body: {
    fontSize: "22px",
    color: "#313131",
  },
  bold: {
    fontSize: "22px",
    color: "#313131",
    fontWeight: "bold",
  },
  ul: {
    padding: "20px",
    fontSize: "22px",
    color: "#313131",
  },
  cta: {
    fontSize: "22px",
    marginTop: "60px",
  },
}));

function BlogPage7(props) {
  const [md, setMD] = useState("");
  const classes = useStyles();

  useEffect(() => {
    fetch(blog)
      .then((response) => response.text())
      .then((data) => {
        console.log("365APPS ~  ~ .then ~ data", data);
        setMD(data);
      });
  }, []);

  return (
    <>
      <Meta
        title="Unlock Learning: Top Educational YouTube Channels for Kids"
        description="Dive into the amazing world of YouTube learning"
      />

      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Box textAlign="left">
            <Grid
              container={true}
              justifyContent="left"
              className={classes.paperItems}
            >
              <Grid item={true} direction="vertical">
                <ReactMarkdown
                  children={md}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    img({ node, ...props }) {
                      return <img className={classes.image} src={props.src} />;
                    },
                    h1({ node, ...props }) {
                      return (
                        <Typography className={classes.title} variant="h1">
                          {props.children}
                        </Typography>
                      );
                    },
                    h2({ node, ...props }) {
                      return (
                        <Typography className={classes.subTitle} variant="h2">
                          {props.children}
                        </Typography>
                      );
                    },
                    h3({ node, ...props }) {
                      return (
                        <Typography className={classes.subheader} variant="h3">
                          {props.children}
                        </Typography>
                      );
                    },
                    p({ node, ...props }) {
                      return (
                        <Typography className={classes.body} variant="body">
                          {props.children}
                        </Typography>
                      );
                    },
                    ul({ node, ...props }) {
                      return (
                        <Typography className={classes.ul} variant="ul">
                          {props.children}
                        </Typography>
                      );
                    },
                  }}
                ></ReactMarkdown>
              </Grid>

              <Grid item={true} direction="vertical">
                <br />
                <br />
                <Typography className={classes.subTitle} variant="h2">
                  {" "}
                  Ready to take control of your screen time? Take Action Now!
                </Typography>{" "}
                <br />
                <br />
                <Typography className={classes.body} variant="body">
                  At FilterFred, we're passionate about helping you achieve a
                  balanced digital life. Take the first step towards setting
                  time limits on YouTube and enhancing your productivity. Click
                  the button below to explore more about our unique and
                  easy-to-use app!
                </Typography>
                <br />
                <br />
                <Button
                  className={classes.cta}
                  component={Link}
                  variant="contained"
                  color="primary"
                  size="large" to="/"
                >
                  {" "}
                  <Typography>Try Filter Fred today! </Typography>
                </Button>{" "}
                <br />
                <br />
                {/*
                <Typography className={classes.subheader} variant="h3">
                
                  Because every minute offline counts towards a healthier,
                  balanced lifestyle.
                </Typography> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>

      {/* <ReactMarkdown>This is a [test](https://google.com)</ReactMarkdown> */}
    </>
  );
}

export default BlogPage7;
