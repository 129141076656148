import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  dashboardSection: {
    border: "1px red solid",
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  gridparent: {
    // border: "1px red solid",
    marginBottom: "10px",
  },
}));
function Status(props) {
  console.log("365APPS  Status  props:", props);
  const classes = useStyles();

  return (
    <Paper>
      <Grid container justifyContent="space-around" padding="10px">
        <Grid item className={classes.gridChild}>
          <Typography variant="h6">App Status</Typography>
        </Grid>
        <Grid item className={classes.gridChild}>
          <Typography variant="h5" color="primary">
            {props.loggedinStatus}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" padding="10px">
        <Grid item className={classes.gridChild}>
          <Typography variant="h6">Last logged time</Typography>
        </Grid>
        <Grid item className={classes.gridChild}>
          <Typography variant="h5" color="primary">
            {props.latestTimeValue ? props.latestTimeValue : "Nothing Yet"}
          </Typography>
        </Grid>
      </Grid>

      <Divider />
    </Paper>
  );
}

export default Status;
