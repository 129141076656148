import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  
  // console.log('365APPS  AuthPage  props:', props);

  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bgColor="primary"
        size="medium"
        bgImage="https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?auto=format&fit=crop&w=1200&h=800&q=80"
        bgImageOpacity={0.8}
        type={props.match.params.type == "signin" ? "signin" : "signup"}
        providers={["google", 
        //"facebook", "twitter"
      ]}
        path={router.pathname}
        afterAuthPath="/dashboard"
      />
    </>
  );
}

export default AuthPage;
