import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Section from "../components/Section";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import blog1 from "./../pageContent/blog1.md";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "./../util/router";
import Button from "@material-ui/core/Button";
//images
import fam6 from "./../images/fam6.png";
import fam7 from "./../images/fam7.png";
import fam8 from "./../images/fam8.png";
import fam9 from "./../images/fam9.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "36px",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  subTitle: {
    fontSize: "28px",
    paddingTop: "6px",
    paddingBottom: "6px",
    color: theme.palette.primary.main,
  },
  subheader: {
    fontSize: "24px",
    paddingTop: "6px",
    paddingBottom: "6px",
    color: theme.palette.primary.main,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  body: {
    fontSize: "22px",
    color: "#313131",
  },
  bold: {
    fontSize: "22px",
    color: "#313131",
    fontWeight: "bold",
  },
}));

function BlogPage4(props) {
  const [md, setMD] = useState("");
  const classes = useStyles();

  useEffect(() => {
    fetch(blog1)
      .then((response) => response.text())
      .then((data) => {
        setMD(data);
      });
  }, []);

  return (
    <>
      <Meta
        title="A Step-by-Step Guide to Limiting Screen Time on YouTube"
        description="Get help with your child's YouTube addiction"
      />

      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Box textAlign="left">
            <Grid
              container={true}
              justifyContent="left"
              className={classes.paperItems}
            >
              <Grid item={true} direction="vertical">
                <Typography variant="h1" className={classes.title}>
                  A Step-by-Step Guide to Limiting Screen Time on YouTube
                </Typography>

                <Typography variant="h3" className={classes.subTitle}>
                  Get help with your child's YouTube addiction
                </Typography>
              </Grid>
              <Grid item={true} direction="vertical">
                <figure>
                  <img
                    src={fam6}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>
                <Typography variant="h3" className={classes.subTitle}>
                  Introduction
                </Typography>
                <Typography variant="body" className={classes.body}>
                  In today's digital age, parents are faced with the challenging
                  task of managing their children's screen time. YouTube, a
                  platform teeming with a vast array of content, is one of the
                  most popular online platforms among kids. However, too much of
                  a good thing can have its drawbacks. Excessive screen time can
                  lead to a myriad of negative effects, including sleep
                  disturbances, decreased physical activity, and even impaired
                  social skills. YouTube is not oblivious to these concerns. In
                  fact, they've made significant strides in recent years to
                  provide parents with more control over their children's
                  viewing habits. Key among these are the YouTube Kids app and
                  its wide range of parental controls and settings, designed to
                  not only protect young viewers from inappropriate content but
                  also to help parents limit screen time effectively. From the
                  built-in timer that gently alerts children when it's time to
                  stop watching, to the ability to set content restrictions and
                  time limits, these features are a game-changer for concerned
                  parents. However, navigating through these settings can seem
                  daunting. This guide aims to demystify the process, providing
                  a step-by-step walkthrough to help you limit screen time on
                  YouTube, ensuring a healthier balance between screen time and
                  other essential activities for your child.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                  Setting Up Parental Controls on YouTube Kids
                </Typography>
                <Typography variant="body" className={classes.body}>
                  In this digital age, making sure children have a balanced
                  lifestyle is paramount, but it can be challenging as they are
                  increasingly drawn to entertaining online platforms like
                  YouTube. One of the tools parents can leverage to manage their
                  child's screen time is the parental control feature on YouTube
                  Kids. By setting time limits, parents can ensure their
                  children enjoy their favorite YouTube content while also
                  maintaining a healthy balance between screen time and other
                  essential activities.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                  Accessing the YouTube Kids settings
                </Typography>
                <Typography variant="body" className={classes.body}>
                  To access these parental controls, start by opening the
                  YouTube Kids app. Look for the Lock icon, which is located in
                  the bottom corner of any page in the app. Tap on this lock and
                  you'll either be asked to enter the numbers that appear on the
                  screen or to input your custom passcode. Once you've done
                  this, you'll be directed to the settings menu. Here, you’ll
                  want to choose the Timer option.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                  Setting the Time Limit
                </Typography>
                <Typography variant="body" className={classes.body}>
                  This is where you can set a specific time limit for your
                  child's YouTube Kids use. You can adjust the time by using the
                  slider bar or the + and - icons. While the time limit can be
                  set according to personal preference, it's important to
                  remember the recommended daily screen time limits for
                  children. The World Health Organization suggests no more than
                  one hour of sedentary screen time for children aged 2 to 5,
                  while older children and teenagers should have consistent
                  limits set that ensure sufficient physical activity, sleep,
                  and other behaviors essential to health.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                  Starting the Timer
                </Typography>
                <Typography variant="body" className={classes.body}>
                  Once you've set a time limit that you're comfortable with,
                  it's time to start the timer. Simply tap on the Start timer
                  button, and the countdown will begin. This timer will run in
                  the background while your child is using the YouTube Kids app.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                  Notifying Kids of Time's Up
                </Typography>
                <Typography variant="body" className={classes.body}>
                  When the time limit is reached, the app will display a
                  friendly "Time's up!" notification to the child. This is a
                  gentle but firm reminder that their allocated YouTube time for
                  the day has ended. Additionally, the app will be locked once
                  the time limit is reached, preventing further video viewing
                  unless the timer is reset by a parent. With these steps,
                  managing your child's YouTube screen time becomes an easy and
                  straightforward process. This not only ensures a safer online
                  experience for your child but also promotes a balanced
                  lifestyle where screen time is just one part of their day.
                </Typography>
                <Typography variant="h3" className={classes.subTitle}>
                  Unlocking the Screen
                </Typography>
                <Typography variant="body" className={classes.body}>
                  In today's digital age, limiting your child's screen time on
                  YouTube is as important as ensuring they eat their vegetables.
                  But what happens when the timer runs out, and your child wants
                  to continue their YouTube journey? Don't worry, you can unlock
                  the screen in a few easy steps.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  To unlock the screen, you need to tap on the lock icon located
                  in the bottom corner of any page in the app. This icon is like
                  your personal key to controlling your child's YouTube usage.
                  Once you tap on it, you'll either see some numbers appear or
                  be prompted to enter your custom passcode. This passcode is
                  like a password that protects your settings from being altered
                  without your permission.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  After you've successfully entered the numbers or your custom
                  passcode, you'll need to select the 'Timer' option. This is
                  where you can manage the time limit for your child's YouTube
                  usage. It's a handy tool that allows you to maintain a balance
                  between your child's screen time and other activities.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  Finally, tap on 'Stop timer'. This effectively pauses the
                  timer and unlocks the screen, allowing your child to continue
                  watching their favorite YouTube content. However, remember to
                  set a new time limit to ensure your child's screen time
                  remains in check.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  Managing screen time doesn't have to be a daunting task. With
                  these simple steps, you can ensure your child enjoys YouTube
                  content responsibly.
                </Typography>
                <figure>
                  <img
                    src={fam7}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>
                <Typography variant="h3" className={classes.subTitle}>
                  Additional Tips for Limiting Screen Time on YouTube
                </Typography>
                <br />
                <Typography variant="body" className={classes.body}>
                  While YouTube has provided parents with some built-in tools to
                  limit screen time, it's crucial to remember that technology
                  alone cannot fully address the issue. Successful screen time
                  management often involves a holistic approach that includes
                  parental involvement, clear boundaries, and alternative
                  activities. Here are some additional strategies that can be
                  beneficial.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  Firstly, suggest alternative activities to your child that can
                  replace excessive screen time. It's essential to provide
                  engaging options that can compete with the allure of YouTube.
                  This could involve outdoor activities, board games, creative
                  projects, or even reading. By introducing these alternatives,
                  you ensure that your child's time is not solely occupied by
                  screen-based entertainment.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  Secondly, encourage parents to set boundaries and create a
                  screen time schedule. Consistency is key when it comes to
                  managing screen time effectively. Using tools like [Filter
                  Fred](https://www.filterfred.app/), parents can easily set up
                  schedules for when YouTube is accessible. This not only helps
                  to limit overall screen time but also instills a sense of
                  discipline and routine in your child's daily activities.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  Finally, emphasize the importance of parental involvement and
                  communication in managing screen time. Parents should be aware
                  of their child's online activities and should have regular
                  discussions about healthy Internet use. Tools like Filter Fred
                  allow parents to stay updated on their child's YouTube
                  activity, fostering an environment of transparency and trust.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  Remember, limiting screen time on YouTube is not just about
                  restricting access. It's about creating a balanced digital
                  lifestyle for your child, where they can enjoy the benefits of
                  online media while also participating in other enriching
                  activities. By combining the use of parental control tools
                  with active involvement and alternative activities, parents
                  can ensure a healthier and more balanced screen experience for
                  their kids. Conclusion
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  In the digital age, it's essential to balance our children's
                  screen time, and YouTube is no exception. We've navigated
                  through the steps to limit screen time on YouTube Kids,
                  starting from setting up a timer to gently alert your child
                  when it's time to stop watching. We've also learned about
                  using advanced tools like Filter Fred, which not only controls
                  the amount of time your child spends on YouTube but also
                  ensures that they are exposed to age-appropriate content.
                </Typography>
                <Typography variant="body" className={classes.body}>
                  Managing screen time is not about taking away fun or stifling
                  curiosity, but about creating a healthy balance. Studies have
                  shown that excessive screen time can affect a child's sleep,
                  social skills, and even their physical health. By taking
                  proactive steps to regulate screen time, you're helping to
                  foster your child's development and well-being.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  In addition to using the built-in timer on YouTube Kids and
                  the Filter Fred app, remember that YouTube's Parental Controls
                  offer significant measures to ensure the content your children
                  view is suitable for their age group. And let's not forget the
                  YouTube Kids app, a dedicated platform for kids, with content
                  restrictions and time limits.
                </Typography>
                <br />
                <br />
                <Typography variant="body" className={classes.body}>
                  The journey to manage your child's screen time may seem
                  daunting at first, but the rewards are worth the effort. Let's
                  remember that our ultimate goal is to enable our children to
                  make the most of the internet in a safe, balanced, and
                  productive way. As parents, we have the power and
                  responsibility to guide them in this journey. So, let's take
                  that first step today, for a healthier, safer digital
                  experience for our kids.
                </Typography>
                <br />
                <br />

                <figure>
                  <img
                    src={fam8}
                    alt="illustration"
                    className={classes.image}
                  />
                </figure>
              </Grid>

              <Grid item={true} direction="vertical">
                <br />
                <br />
                <Typography className={classes.subTitle} variant="h2">
                  Inspired to start managing your screen time on YouTube? Why
                  not take it a step further with FilterFred?{" "}
                </Typography>{" "}
                <br />
                <br />
                <Typography className={classes.body} variant="body">
                  Our app provides you with the tools to not just limit but also
                  monitor and manage your digital life more effectively. To
                  learn more about how FilterFred can make your journey towards
                  healthier screen time easier, click the button below.
                </Typography>
                <br />
                <br />
                <Button
                  className={classes.cta}
                  component={Link}
                  variant="contained"
                  color="primary"
                  size="large" to="/"
                  // fullWidth={true}
                >
                  <Typography>Try Filter Fred today! </Typography>
                </Button>{" "}
                <br />
                <br />
                <Typography className={classes.subheader} variant="h3">
                  Get started with FilterFred today, and take the first steps
                  towards a balanced and controlled digital lifestyle.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>

      {/* <ReactMarkdown>This is a [test](https://google.com)</ReactMarkdown> */}

      {/* <ReactMarkdown># Hello, *World*!</ReactMarkdown> */}
    </>
  );
}

export default BlogPage4;
