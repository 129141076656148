import React from "react";
import PricingSection from "../components/PricingSection";
import Meta from "./../components/Meta";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection></PricingSection>
    </>
  );
}

export default PricingPage;
