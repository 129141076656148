import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    display: "block",
    height: "auto",
    width: "100%",
  },
  mainHeading: {
    color: theme.palette.primary.dark,
    
    fontFamily: "Slackey-Regular, Arial, sans-serif", // Add the custom font name here
  },
  subHeading: {
      color: "#fff",
      
      fontFamily: "Slackey-Regular, Arial, sans-serif", // Add the custom font name here
  }
}));

function HeroSection(props) {
  const classes = useStyles();

  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              {/* <SectionHeader title={props.title} subtitle={props.subtitle} size={4} 
                className={classes.mainHeading}
                /> */}
              <Typography variant="h4" component="h2" className={classes.subHeading}>
                Provide a Kid-Friendly YouTube Experience with 
              </Typography>
                <Typography variant="h1" className={classes.mainHeading}>

                
                  Filter Fred
                
              </Typography>
              <Typography variant="h5" component="h5" className={classes.subHeading}>
                
              Age-Appropriate YouTube for kids 
              </Typography>
              <Typography variant="h5" component="h5" className={classes.subHeading}>
              powered by AI
              </Typography>
              <Button component={Link} to={props.buttonPath} variant="contained" size="large" color={props.buttonColor}>
                {props.buttonText}
              </Button>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={true}>
            <figure>
              <img src={props.image} alt="illustration" className={classes.image} />
            </figure>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection;
