import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HistoryDataTable from "./DataTable";
import Status from "./Status";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import Paper from "@material-ui/core/Paper";
import ControlPanelData from "./ControlPanelData";
import FeaturesSection from "./FeaturesSection";

const useStyles = makeStyles((theme) => ({
  dashboardSection: {
    // border: "1px red solid",
    paddingTop: "50px",
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  accent: {
    color: theme.secondary,
  },
  gridparent: {
    // border: "1px red solid",
    marginBottom: "10px",
  },
  container1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    margin: "50px",
  },
}));

function DashboardSection(props) {
  // console.log("365APPS  DashboardSection  props:", props);
  const classes = useStyles();

  const auth = useAuth();
  console.log("365APPS  DashboardSection  auth.user:", auth.user);
  const router = useRouter();
  const [latestTimeValue, setLatestTimeValue] = React.useState("");
  const [loggedinStatus, setLoggedinStatus] = React.useState("");
  const [isVisible, setIsVisible] = React.useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const latestTime = (value, value1) => {
    setTimeout(() => {
      setLatestTimeValue(value + " " + value1);
      console.log("365APPS  latestTime  value:", value, value1);
    }, 1000);
  };

  const statusCallback = (value) => {
    setTimeout(() => {
      console.log("365APPS  statusCallback  value:", value);
      if (value === true) {
        value = "Logged in";
        setLoggedinStatus(value);
      } else {
        value = "Logged out";
        setLoggedinStatus(value);
      }
    }, 1000);
  };

  return (
    <Section
      id="DashboardSection"
      className={classes.dashboardSection}
      bgColor={props.bgColor}
      // size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        className="container1"
        // maxWidth={false}
      >
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid direction="column" container={true} spacing={4}>
          <Grid>
            {router.query.paid && auth.user.planIsActive && (
              <Box mx="auto" mb={4} maxWidth={400}>
                <Alert severity="success">
                  You are now subscribed
                  {/* to the {auth.user.planId} plan */}
                  <span
                    role="img"
                    aria-label="party"
                    style={{ marginLeft: "10px" }}
                  >
                    🥳
                  </span>
                </Alert>
              </Box>
            )}
          </Grid>

          <Grid>
            <Box mx="auto" mb={4}>
              {/* <DashboardItems />  */}
              {/* </Grid>
          <Grid item={true} xs={12} md={6}> */}
              <Paper>
                <Box mx="auto" p={2} minHeight={240}>
                  <Grid item>
                    <Typography variant="h6" paragraph={true}>
                      <strong>Subscription info</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs>
                    {!auth.user.stripeSubscriptionId && (
                      <>
                        <Typography variant="h6" paragraph={true}>
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            <strong>Inactive</strong>
                          </span>
                        </Typography>
                        <Typography variant="h5" paragraph={true}>
                          To activate your account, you can signup via{" "}
                          <LinkMui component={Link} to="/pricing">
                            <span
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              <strong>pricing</strong>
                            </span>
                          </LinkMui>
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid item>
                    You are signed in as{" "}
                    <Typography
                      component="div"
                      style={{
                        fontFamily: "arial",
                        color: "green",
                      }}
                    >
                      <strong>{auth.user.email}</strong>.
                    </Typography>
                  </Grid>
                  <Grid item>
                    {auth.user.stripeSubscriptionId && (
                      <>
                        {/* <Typography component="div">
                          <div>
                            You are subscribed
                              to the{" "}
                            <span
                              style={{
                                color: "green",
                              }}
                            >
                              <strong>{auth.user.planId} plan</strong>.{" "}
                            </span>
                          </div> 
                        </Typography> */}
                        <Typography component="div">
                          <div>
                            Your plan status is{" "}
                            <span
                              style={{
                                color:
                                  auth.user.stripeSubscriptionStatus ===
                                  "active"
                                    ? "green"
                                    : auth.user.stripeSubscriptionStatus ===
                                      "trialing"
                                    ? "blue"
                                    : "red",
                              }}
                            >
                              <strong>
                                {auth.user.stripeSubscriptionStatus}
                              </strong>
                              .
                            </span>
                          </div>
                        </Typography>
                        <Typography component="div">
                          <div>
                            You can manage your subscription{` `}
                            {auth.user.stripeSubscriptionId && (
                              <>and plan{` `}</>
                            )}
                            in{` `}
                            <LinkMui component={Link} to="/settings/billing">
                              <strong>billing</strong>
                            </LinkMui>
                          </div>
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item>
                    You can change your account info{` `}
                    {/* {auth.user.stripeSubscriptionId && <>and plan{` `}</>} */}
                    in{` `}
                    <LinkMui component={Link} to="/settings/general">
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        <strong>settings</strong>
                      </span>
                    </LinkMui>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Grid>

          <Grid>
            <Paper>
              <Box mx="auto" mb={4} p={2}>
                <Button
                  style={{
                    color: "black",
                  }}
                  startIcon={
                    isVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                  }
                  variant="contained"
                  color="primary"
                  onClick={toggleVisibility}
                >
                  {isVisible ? "Hide" : "how to get started"}
                </Button>
                {isVisible && (
                  <FeaturesSection
                    bgColor="secondary"
                    size="medium"
                    bgImage=""
                    bgImageOpacity={1}
                    title="Get Started with Filter Fred in 4 Simple Steps:"
                    subtitle=""
                  />
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid>
            <Box mx="auto" mb={4}>
              <Status
                loggedinStatus={loggedinStatus}
                latestTimeValue={latestTimeValue}
              />
            </Box>
          </Grid>

          <Grid>{/* <ControlPanel /> */}</Grid>
          <Grid>
            <Box mx="auto" mb={4}>
              <ControlPanelData statusCallback={statusCallback} />
            </Box>
          </Grid>
          <Grid>
            {!auth.user.stripeSubscriptionId ? (
              <Box mx="auto" mb={4}>
                Not showing History just yet, waiting for active subscription.
              </Box>
            ) : (
              <Box mx="auto" mb={4}>
                <HistoryDataTable callback={latestTime} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSection;
