import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import LegalSection from "./../components/LegalSection";
import { requireAuth } from "./../util/auth";

function DashboardPage(props) {
  // console.log('365APPS  DashboardPage  props:', props);
  return (
    <>
      <Meta title="Dashboard" />
      
      <DashboardSection
        bgColor="default"
        size="large"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
      <LegalSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        section="terms-of-service"
        key="terms-of-service"
      />
    </>
  );
}

export default requireAuth(DashboardPage);
